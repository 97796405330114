export const bm = {
  data: {
    dashboard: {
      create_order: 'BUAT PESANAN',
      pick_up: 'PENGAMBILAN',
      create_order_desc: 'Adakah anda ingin menghantar sesuatu hari ini?',
      single_shipment: 'Penghantaran Tunggal',
      bulk_shipment: 'Penghantaran Pukal',
      search_bar: 'Cari mengikut no. pengesanan penghantaran, nama, emel, atau no. telefon',
      account_number: 'No Akaun',
      my_profile: 'Profil Saya',
      log_out: 'Log keluar',
      home: 'Laman Utama',
      x_order_pickup1: 'Anda mempunyai ',
      x_order_pickup2: 'pesanan ',
      x_order_pickup3: 'sedia diambil',
      request_pickup: 'Permintaan untuk Pengambilan',
      my_orders: 'Pesanan saya',
      select_date: 'Pilih Jangka Tarikh',
      start_date: 'Tarikh Mula',
      end_date: 'Tarikh Akhir',
      my_shipment: 'Penghantaran Saya',
      pending_pickup: 'Menunggu Pengambilan',
      live_shipments: 'Penghantaran langsung',
      delivered: 'Berjaya Dihantar',
      failed_deliveries: 'Gagal Dihantar',
      returns: 'Pengembalian',
      my_cod: 'Pesanan COD saya',
      see_orders: 'Lihat Pesanan',
      collected: 'Diterima daripada penerima',
      pending_collection: 'Menunggu Kutipan',
      failed_collect: 'Gagal dikumpul',
      news_promo: 'BERITA DAN PROMOSI',
      insights: {
        performance_insights: 'PRESTASI ANDA',
        total_shipment_sent: 'Jumlah Penghantaran',
        last: '30 hari lalu',
        cod_noncod: 'COD/Non COD',
        sent_by_product: 'Jumlah penghantaran mengikut produk',
        sent_by_state: 'Jumlah penghantaran mengikut negeri',
        top_state_shipments: 'Negeri Teratas',
        state: 'NEGERI',
        order_count: 'BILANGAN PESANAN',
        percentage: 'PERATUS',
        order_sent: 'Pesanan yang dihantar',
        cod: 'COD',
        non_cod: 'NON COD',
        melplus: 'MELPLUS',
        mps: 'MPS',
        pos_laju: 'POS LAJU',
        ems: 'EMS',
        air_parcel: 'AIR PARCEL',
        surface_parcel: 'SURFACE PARCEL',
        delivery_type: 'jenis penghantaran'
      }
    },
    menu:{
      home: 'Laman Utama',
      shipments: 'Penghantaran',
      single_shipment: 'Penghantaran Tunggal',
      bulk_shipment: 'Penghantaran Pukal',
      my_shipment: 'Penghantaran Saya',
      contact: 'Kenalan',
      profile: 'Profil Saya',
      user_profile: 'Profil Pengguna',
      billing: 'Pengebilan',
      invoice: 'Invois',
      logout: 'Log keluar',
      reports: 'Laporan',
      search: 'Cari',
      rate_calculator: 'Kalkulator Kadar',
      user_management: 'Pengurusan Pengguna',

      linked_account: 'Akaun Berkait',
      available_account: "Akaun-akaun Sedia Ada",
      company_name: "Nama Syarikat",
      account_number: "Nombor Akaun",
      type: "Jenis",
      permission: "Kebenaran",
      switch_account_failed: "Gagal menukar akaun",
      ok: "ok",
      view_account: "Lihat Akaun",
      master: 'Induk',
      sub: 'Sub',
      set_as_default: 'Set sebagai piawai',
      default_account: 'Akaun Utama',

      select_all:'Pilih semua',
      date_range:'Julat Tarikh',
      required_date_range: 'Sila Pilih Julat Tarikh',
      shipment_status:'Status Penghantaran',
      required_shipment_status:'Pilih Status Penghantaran',
      payment_type:'Jenis Pembayaran',
      required_payment_type:'Pilih Jenis Pembayaran',
      product:'Produk',
      required_product:'Pilih Produk',
      reset_filter:'Tetapkan Semula Penapis',
      search_by:'Cari mengikut no penjejakan, nama, e-mel atau no telefon.',
      more:'Lagi',
    },
    pickupCardList:{
      pick_up_address: 'Alamat Pengambilan',
      add_pick_up_address: '+ Tambah Alamat Pengambilan',
      return_address: 'Pilih alamat pengembalian dari daftar di bawah ini',
      select_address_list: 'Pilih Alamat Ambil daripada senarai di bawah',
      please_note: 'Harap maklum',
      change_note: 'Alamat Pengambilan untuk pesanan penghantaran pukal tidak boleh ditukar.',
      next: 'Seterusnya',
      note1: "Tiada Alamat",
      note2: 'Pengambilan tersedia.',
      note3: 'Untuk mula menghantar item anda,',
      note4: 'sila tambah Alamat Pengambilan.',
    },
    recipientDetail:{
      loading: 'Sila tunggu',
      edit: 'Ubah',
      no_record_found: 'Tiada rekod dijumpai.',
      recipient_details: 'Butiran Penerima',
      search_contact: 'Cari Kenalan',
      search: 'Cari',
      search_placeholder: 'Cari Nama, Alamat atau Syarikat',
      no_result: 'Tiada keputusan',
      email_note: 'Nota: Anda boleh mengemaskini alamat yang dijana secara automatik atau memasukkan alamat anda sendiri.',
      postcode_require_message: 'Masukkan 0000 jika tiada poskod',
      save_contact: "Simpan ke Kenalan",
      next: 'Seterusnya',
      search_contact_error_message: 'Ralat telah berlaku!',
      invalid_sender_postcode: 'Poskod penghantar tidak sah. Sila berikan poskod yang sah.',
    },
    bulkShipment: {
      bulk_shipment: 'Penghantaran Pukal',
      bulk: 'Pukal',
      shipment: 'Penghantaran',
      template: 'templat',
      home: 'Laman Utama',
      domestic: 'DOMESTIK',
      domestic_lower: 'Domestik',
      international: 'Antarabangsa',
      below: 'di bawah',
      download_the: 'Muat turun',
      contact_upload: 'Muat Naik Kenalan',

      steps_to_create: 'Langkah untuk membuat',
      bulk1: 'pukal',
      shipment1: 'penghantaran',
      should_there_be_errors: "Sekiranya terdapat ralat dengan fail yang anda muat naik, muat turun fail ralat yang diberikan.",
      edit_and_reupload: "Sunting medan yang ditekanan dalam fail ralat dengan butiran yang betul dan muat naik semula fail yang telah disunting.",
      within_east_west: 'Untuk penghantaran dalam Malaysia Timur dan Barat sahaja',
      outside_malasiya: 'Untuk penghantaran ke luar Malaysia',
      contact_upload_note:'Setiap muat naik fail boleh termasuk sehingga 1000 kenalan.',
      contact_upload_templete: 'Hubungi Templat Muat Naik Pukal',
      upload_bulk_file:
        'Muat naik fail pukal yang diisi dengan meletakkannya di sini atau dengan mengklik Muat Naik dari Komputer',
      generate_connotes: 'Hasilkan nota konsainan',
      click_submit: 'Klik Hantar',
      upload_compoter: 'Muat naik dari Komputer',
      add_contacts:
        'Tambahkan kenalan anda ke dalam medan yang disediakan dalam templat.',
      add_contacts_important_note:
        'Nota Penting: Jangan padamkan mana-mana lajur daripada templat.',
      add_contacts_own_format:
        'Hanya tambah kenalan anda sendiri dengan mengikut format yang diberikan.',
      successful_upload: 'Untuk memastikan muat naik berjaya:',
      please_use: 'Sila guna',
      latest_template: 'templat yang terkini',
      provided_above: 'disediakan di atas.',
      alter_template:
        'Sila jangan ubah templat (fail Excel), tambah/buang ruangan, dll.',
      file_error:
        'Jika fail yang dimuat naik menunjukkan ralat, sila muat turun fail ralat.',
      error_details:
        'Fail ralat akan menunjukkan butiran ralat. Sila betulkan dan muat naik semula fail ralat yang sama.',
      drop_file: 'Letakkan fail .xls, .xlsx, .xlsm anda di sini atau',
      or: 'atau',
      hs_code_list: 'Isikan Kod HS dan Kod Negara berdasarkan senarai',
      here: 'di sini',

      required_image: 'Sila pilih imej',
      Uh_oh: 'Uh-oh',
      found_error_in_upload: 'kami mendapati ralat dalam fail yang anda muat naik',
      require_templete_contact_excel: 'Sila gunakan templat excel kenalan yang berkaitan',
      templete_outdated: 'Templat penghantaran pukal anda nampaknya sudah lapuk/ tidak dalam format yang betul. Sila muat turun templat terkini dan muat naik semula fail.',
      error_while_upload: 'Kesilapan telah berlaku semasa memuat naik fail pukal, sila cuba sebentar lagi',
      error_not_as_per_templete: 'Fail pukal anda tidak seperti templat, sila semak semula fail anda dan cuba lagi',
      error_domestic_templete: 'Sila gunakan templat CODUbat Domestik',
      error_domestic_cod_templete: 'Sila gunakan templat COD Domestik',
      error_domestic_noncod_templete: 'Sila gunakan templat NON COD Domestik',
      error_melplus_templete: 'Sila gunakan templat MelPlus',
      error_mps_templete: 'Sila gunakan templat MPS',
      error_international_templete: 'Sila gunakan templat Antarabangsa',
      success_added_contact: 'Penerima berjaya ditambahkan ke dalam kenalan anda.',
      error_empty_field: 'Nampaknya terdapat medan/ralat kosong dalam medan. Sila betulkan maklumat dan muat naik semula fail.',
      error_multiple: 'Satu atau lebih medan dalam fail didapati tidak betul atau tidak mencukupi. Keputusan telah disusun dalam fail yang dimuat turun. Sila ubah medan dan muat naik semula sekali lagi untuk meneruskan permintaan anda.',
      download: 'Muat turun',
    },
    myShipments: {
      home: 'Laman Utama',
      shipment: 'Penghantaran',
      shipment_detail:"Penghantaran Butiran",
      my_shipments: 'Penghantaran Saya',
      edit_order: 'Ubah Pesanan',
      request: 'PERMINTAAN',
      for_pickup: 'UNTUK PENGAMBILAN',
      pending: 'PENGHANTARAN',
      shipments: 'TERTUNGGU',
      live: 'PENGHANTARAN',
      live_shipment: 'SECARA LANGSUNG',
      delivered: 'TELAH DIHANTAR',
      failed: 'PENGHANTARAN',
      deliveries: 'GAGAL',
      return: 'PENGHANTARAN',
      return_shipments: 'PENGEMBALIAN',
      all: 'SEMUA',
      tracking_details:"BUTIRAN PENGESANAN PENGHANTARAN",
      recipient:"PENERIMA",
      parcel:'BUNGKUSAN',
      delivery_detail:"BUTIRAN PENGHANTARAN",
      to: 'ke',
      action:"TINDAKAN",
      type:"JENIS",
      pick_up_date:"TARIKH PENGAMBILAN",
      pick_up_info:"INFO PENGAMBILAN",
      no_tracking_id:"TIADA ID PENJEJAKAN",
      not_scheduled:"PENGAMBILAN TIDAK DIJADUALKAN",
      pick_up_scheduled:"PENGAMBILAN DIJADUALKAN",
      pick_up_re_scheduled:"PENGAMBILAN DIJADUAL SEMULA",
      pick_up_cancelled:"PENGAMBILAN DIBATALKAN",
      partial_picked_up:"DIAMBIL SEBAHAGIAN",
      pending_drop_off:"PENTING DROP-OFF",
      in_transit:"DALAM TRANSIT",
      picked_up:"DIAMBIL",
      out_for_delivery:"KELUAR UNTUK PENGHANTARAN",
      self_collect:"KUMPUL SENDIRI",
      dropped_off:"DITURUNKAN",
      pickup_failed: "GAGAL AMBIL",
      returned_table:"DIKEMBALIKAN",
      return_sender:"KEMBALI KEPADA PENGHANTAR",
      return_success:"PENGEMBALIAN BERJAYA",
      pick_up_address: 'Alamat Pengambilan',
      weight:"BERAT (KG)",
      reason:"SEBAB",
      store:"KEDAI",
      channel:"SALURAN",
      non_cod:"BUKAN COD",
      download: 'Muat Turun',
      commercial_invoice:"Invois Komersial (.pdf)",
      get_consignment_note:"Dapatkan Nota Konsainan",
      no_data:"Tiada data",
      delay_in_delivering:"Eh-oh, terdapat kelewatan dalam menghantar halaman ini. Tolonglah",
      refresh:"segarkan semula",
      update: 'Kemas kini',
      add: 'Tambah',

      order: 'Pesanan',
      selected: 'dipilih',

      no_tracking_id2: 'Tiada ID penjejakan',
      pick_up_not_scheduled: 'Pemgambilan Tidak Dijadualkan',
      all2: 'Semua',

      add_order_to_pickup: 'Tambah Pesanan Untuk Diambil',
      cancel_shipment: 'Batalkan penghantaran',
      reschedule: 'Jadual semula',
      cancel: 'Batal',
      cancelled: 'Batal',
      edit_shipment: 'Ubah Penghantaran',
      delete_shipment: 'Padamkan Penghantaran',
      delete_store: 'Padamkan Kedai',
      track: 'Pengesanan penghantaran',
      view: 'Lihat',
      print: 'Cetak',

      request_pickup_note: 'Request Pengambilan',
      pending_pickup_note: 'Pending Pengambilan',
      live_shipments_note: 'Penghantaran Langsung',
      delivered_note: 'TELAH DIHANTAR',
      failed_deliveries_note: 'Penghantaran Gagal',
      returned_note: 'Dipulangkan',
      pickup_details_note: 'Pengambilan butiran',

      status: 'STATUS',
      qty: 'Kt',

      thermal_label: 'Label Termal',
      tallysheet: 'Tallysheet',

      parcel_dimensions_guide: "Panduan Dimensi Bungkusan",
      page_copy_note: 'Isi borang di bawah dan penghantaran anda akan dihantar.',
      page_title: 'Hantar satu bungkusan',

      proof_of_delivery: 'Bukti Penghantaran',
      view_proof_of_delivery: 'Lihat Bukti Penghantaran',
      no_result_to_display: 'Tiada hasil ditemui',

      invalid_sender_postcode: 'Poskod penghantar tidak sah. Sila berikan poskod yang sah.',
      close: 'Tutup',
      pending_cancellation:'Menunggu Kelulusan Pembatalan',

      request_pickup: {
        search_tracking: 'Cari ID pengesanan penghantaran',
        search_tracking_placeholder: 'Cari no. penjejakan atau penerima',
        date_range: 'Jangka Tarikh',
        create_consignment: 'Buat Konsainan dan Ambil',
        cget_consignment: 'Buat Catatan Konsainan',
        download_as: 'Muat Turun sebagai',
        commercialinvoice: 'Invois Komersial',
        delete: 'Padam',
        no_record_found: 'tiada rekod ditemui',
        description1: 'Anda boleh memuat turun sehingga 100 nota konsainan pada satu masa. Sila kosongkan nota konsainan yang tinggal untuk meneruskan tindakan muat turun.',
        print_title: 'Cetak Nota Konsainan',
        description2: 'Sila pergi ke tab "Penghantaran Tertunggu" untuk mencetak nota konsainan. Terima kasih!',
        generate_consigment_note: 'Jana Nota Konsainan',
        print_label: 'Cetak Label',
        download_consignment_note: 'Muat turun Nota Konsainan',
        download_tallysheet: 'Muat turun Nota Tallysheet',
        download_consignment_invoice: 'Muat turun Nota Invois',
        delete_order: 'Adakah anda ingin memadam pesanan?',
        dailog_title: 'Uh-oh',
        pickup_message:'Ini adalah mesej statik.',
        dailog_msg1: 'Adakah anda pasti mahu',
        dailog_msg2: 'untuk ini',
        thermal_label: 'Label Termal',
        tallysheet: 'Tallysheet',
        go_now: 'Pergi Sekarang',
      },
      pending_shipments: {
        search_shipments: 'Carian Penghantaran',
        search_shipments_placeholder: 'Cari mengikut no pengambilan dan no penjejakan',
        date_range: 'Jangka Tarikh',
        order_status: 'Status Pesanan',
        order_status_placeholder: 'Pilih Status Pesanan',
        order_status_all: 'Semua',
        order_status_pickup_schedule: 'Pengambilan yang Dijadualkan',
        order_status_pickup_reschedule: 'Pengambilan yang Dijadualkan Semula',
        order_status_partial: 'Pengambilan Separa',
        order_status_dropoff: 'Menunggu Penyerahan',
        view_by: 'Pandangan oleh',
        orders: 'Pesanan',
        pickup_number: 'Nombor Pengambilan',
        print_label: 'Cetak Label',
        download_as: 'Muat Turun sebagai',
        thermal_label: 'Label Termal',
        tallysheet: 'Tallysheet',
        commercialinvoice: 'Invois Komersial',
        no_record_found: 'tiada rekod ditemui',
        cancel_pickup_note: 'Adakah anda pasti akan membatalkan Pengambilan ini? Anda perlu menjadualkan pengambilan baharu selepas pembatalan.',
        reschedule_pickup_title: "Adakah anda ingin membuat Permintaan Ambil",
        reschedule_pickup_description: "Pilih tarikh Pengambilan pilihan anda di bawah. Tarikh Pengambilan yang diprapilih akan berdasarkan tarikh almari (sebelum masa pemotongan).",
      },
      live_shipment_tab: {
        search_orders: 'Cari Pesanan',
        search_orders_placeholder: 'Cari no. penjejakan atau penerima',
        all: 'Semua',
        order_type: 'Jenis Pesanan',
        date_range: 'Jangka Tarikh',
        order_status: 'Status Pesanan',
        picked_up: 'Telah Diambil',
        in_transit: 'Dalam Transit',
        out_for_delivery: 'Sedang dihantar',
        dropped_off: 'Telah diserahkan',
        cod: 'COD (Tunai Semasa Penyerahan)',
        non_cod: 'Bukan COD (Tunai Semasa Penyerahan)',
        select_order_status: 'Pilih Status Pesanan',
        select_order_type: 'Pilih Jenis Pesanan',
        print_label: 'Cetak Label',
        download_as: 'Muat Turun sebagai',
        thermal_label: 'Label Termal',
        tallysheet: 'Tallysheet',
        commercial_invoice: 'Invois Komersial',
      },
      delivered_tab: {
        search_orders: 'Cari Pesanan',
        all: 'Semua',
        cod: 'COD (Tunai Semasa Penyerahan)',
        non_cod: 'Bukan COD (Tunai Semasa Penyerahan)',
        date_range: 'Jangka Tarikh',
        order_type: 'Jenis Pesanan',
        search_orders_placeholder: 'Cari no. penjejakan, nama, lokasi atau no telefon',
        select_order_type: 'Pilih Jenis Pesanan',
        print_label: 'Cetak Label',
        download_as: 'Muat turun sebagai',
        thermal_label: 'Label Termal',
        tallysheet: 'Tallysheet',
        commercial_invoice: 'Invois Komersial',
      },
      failed_deliveries: {
        search_orders: 'Cari Pesanan',
        all: 'Semua',
        cod: 'COD (Tunai Semasa Penyerahan)',
        non_cod: 'Bukan COD (Tunai Semasa Penyerahan)',
        date_range: 'Jangka Tarikh',
        order_type: 'Jenis Pesanan',
        search_orders_placeholder: 'Cari no. penjejakan, nama, lokasi atau no telefon',
        select_order_type: 'Pilih Jenis Pesanan',
        print_label: 'Cetak Label',
        download_as: 'Muat turun sebagai',
        no_record_found: 'tiada rekod ditemui',
        thermal_label: 'Label Termal',
        tallysheet: 'Tallysheet',
        commercial_invoice: 'Invois Komersial',
      },
      returned: {
        search_orders: 'Cari Pesanan',
        all: 'Semua',
        cod: 'COD (Tunai Semasa Penyerahan)',
        non_cod: 'Bukan COD (Tunai Semasa Penyerahan)',
        date_range: 'Jangka Tarikh',
        order_type: 'Jenis Pesanan',
        search_orders_placeholder: 'Cari no. penjejakan, nama, lokasi atau no telefon',
        select_order_type: 'Pilih Jenis Pesanan',
        print_label: 'Cetak Label',
        download_as: 'Muat turun sebagai',
        thermal_label: 'Label Termal',
        tallysheet: 'Tallysheet',
        commercial_invoice: 'Invois Komersial',
      },
      parcel_data :{
        edit: 'Ubah',
        parcel_detail: 'Butiran Bungkusan',
        view_guide: 'Lihat Panduan',
        document: 'Dokumen',
        parcel: 'Bungkusan',
        mps: 'MPS',
        ubat: 'Ubat',
        mel_plus: 'MelPlus',
        parcel_note: 'Nota Bungkusan',
        my_parcel_requires: ' Bungkusan Saya',
        mymel_parcel_requires: 'Mymel parcel requires',
        cash_on_delivery: 'Tunai Semasa Penghantaran (COD)',
        cash_on_delivery2: 'memerlukan Tunai semasa Penghantaran (COD)',
        shipping_insurance: 'Insurans Perkapalan',
        shipping_note1:'Lindungi penghantaran anda',
        shipping_note2:'daripada kerosakan serendah RM1.00',
        insurance_note1: 'Lindungi penghantaran anda dengan perlindungan segera dari serendah RM1.00 setiap penghantaran untuk kategori Pecah, Hilang, Kebakaran, Kerosakan Air dan Kemalangan. Untuk maklumat lanjut tentang barangan berbahaya dan barang terlarang',
        insurance_note2: 'tekan sini',
        insurance_note3: 'Nota penting',
        insurance_note4: 'Nilai maksimum perlindungan sehingga RM10,000 sahaja.',
        insurance_note5: 'Nilai maksimum perlindungan untuk "Multi-piece shipment" adalah sehingga RM10,000 sahaja.',
        custom_declaration_form: 'Borang Akuan Kastam',
        what_are_you_shipping: 'Apakah yang anda hantar?',
        shipment_rate_note: 'Kadar penghantaran akan dikira berdasarkan berat bungkusan atau berat isipadu yang mana lebih tinggi',
        declare_more_item: 'Tambah Item',
        weight_tooltip: 'Kadar penghantaran akan dikira berdasarkan berat bungkusan atau berat isipadu yang mana lebih tinggi',
        melplus_tooltip: 'Kadar penghantaran adalah berdasarkan zon dan peringkat berat (dalam kg).',
        product_list_fail_note: 'Gagal mendapatkan senarai produk',
        origin_country: 'Negara Asal',

        multi_piece_shipment_detail: 'Butiran Penghantaran Berbilang Keping',
        add_shipment: 'Tambah penghantaran',
        invalid_sender_postcode: 'Poskod penghantar tidak sah. Sila berikan poskod yang sah.',
      },
      mps_details: {
        home: "Laman Utama",
        my_shipment: "Penghantaran Saya",
        mps_details: "Butiran MPS",
        pickup_Details: 'Pengambilan butiran',
        multi_piece_shipment_details: "BUTIR-BUTIR PENGHANTARAN PELBAGAI KEPING",
        parent_consignment_note: "Induk Nota Konsainan",
        total_shipment: "Jumlah Penghantaran",
        total_weight: "Jumlah Berat (Kg)",
        print_label: "Cetak Label",
        download_as: "Muat Turun sebagai",
        back_to_pickup: "Kembali ke Pengambilan",
        back_to_pickup_detail: "Kembali ke Butiran Pengambilan",

        thermal_label: 'Label Termal',
        tallysheet: 'Tallysheet',
        commercialinvoice: 'Invois Komersial',
      }
    },
    pickup_details: {
      pickup_details_title: 'Ambil Butiran',
      pick_up_no: 'No. Pengambilan.',
      pick_up_date: 'Tarikh Pengambilan',
      pick_up_address: 'Alamat Pengambilan',
      total_parcel: 'Jumlah Bungkusan',
      total_weight: 'Jumlah Berat (kg)',
      change_pick_up_date: 'Tukar Tarikh Pengambilan',
      add_orders: 'Tambah Pesanan',
      print_label: 'Cetak Label',
      download_as: 'Muat turun sebagai',
      home: 'Laman Utama',
      my_shipments: 'Penghantaran Saya',
      back_data: 'Kembali ke Pengambilan',
    },
    order_details: {
      order_details: 'Butiran Pesanan',
      tracking_details: 'Butiran Pengesanan Penghantaran',
      tracking_no: 'No. Pengesanan Penghantaran',
      store_info: 'Maklumat Kedai',
      order_date: 'Tarikh Pesanan',
      order_time: 'Masa Pesanan',
      print_label: 'Cetak Label',
      tracking_history: 'Sejarah Penjejakan',
      proof_of_delivery: 'Bukti penghantaran',
      no_record_found: 'tiada rekod ditemui',
      sender_details: 'Butiran Penghantar',
      recipient_details: 'Butiran Penerima',
      parcel_details: 'Butiran Bungkusan',
      product_name: 'Nama Produk',
      category: 'Kategori',
      category_details: 'Butiran Kategori',
      dimensions: 'Dimensi(cm)',
      parcel_weight: 'Berat Bungkusan(kg)',
      volumetric_weight: 'Berat Volumetrik (kg)',
      chargeable_weight: 'Berat Dikenakan Caj',
      sum_insured: 'Jumlah Diinsurankan (RM)',
      insurance_premium: 'Premium Insurans (RM)',
      cod_amount: 'Jumlah COD (RM)',
      item_description: 'Huraian Item',
      parcel_notes: 'Nota Bungkusan',
      custom_declarations: 'Deklarasi Kastam',
      weight: 'Berat (kg)',
      Quantity: 'Kuantiti',
      hs_code: 'Kod HS',
      value_rm: 'Nilai (RM)',
      country_of_origin: 'Negara Asal',
      download: 'Muat Turun',
      home: 'Laman Utama',
      my_shipments: 'Penghantaran Saya',
      error_note1: 'Ralat semasa memuat turun. Sila cuba lagi.',
      close: 'Tutup',
      print: 'Cetak',
      track_order: 'Susunan Jejak',
      status: 'Status',
      back_data: 'Kembali ke Pengambilan',

      back_data_detail: 'Kembali ke Butiran Pengambilan',
      pickup_detail: 'Butiran Pengambilan',
      mps_detail: 'Butiran MPS',
      back_to_MPS_details: "Kembali ke Butiran MPS",

      add_orders: 'Tambah Pesanan',
      pickup_no: 'No.Pengambilan',
      pickup_date: 'Tarikh Pengambilan',
      pickup_address: 'Alamat Pengambilan',
      add_pickup: 'Tambah Pengambilan',
      no_data_found: 'Tiada data dijumpai',
      generate_consignment_note: 'Hasilkan Nota Konsainan',
      action_note1: 'Adakah anda pasti mahu',
      action_note2: 'untuk pesanan ini?',
      add_orders_to_pickup: 'Adakah anda pasti mahu menambah Pesanan ini pada Nombor Pengambilan ini?',
      static_message: 'Ini adalah mesej statik.',
      create_reverse_pick_up: 'Buat Pengambilan Balik',
      updated_on: 'Dikemaskini pada',
      uh_oh: 'Uh-oh',
      sender_ref: 'No. Ruj Pengirim',
      convert_to_non_cod: 'Tukar ke Bukan COD',
      confirm_convert_to_non_cod: 'Ya, Tukar ke Bukan COD',
      confirm_cancel_button_to_non_cod: 'Ok, faham',
      cancel_button_to_non_cod: 'Batal',
      cannot_convert_to_non_cod: 'Tidak boleh ditukar ke Bukan COD',
      message_convert_to_non_cod: 'Adakah anda pasti ingin menukar<br>jumlah COD',
      to_non_cod: 'ke Bukan COD?',
      failed_to_convert_to_non_cod: 'Oops, gagal mengemas kini pembayaran anda kepada Bukan COD.',
      failed_to_convert_to_not_available: 'Maaf, COD tidak tersedia untuk akaun ini',
      cancel_shipment: 'Batalkan Penghantaran',
      confirm: 'Adakah anda mengesahkan untuk membatalkan penghantaran ini? <br>Penghantaran yang dibatalkan tidak akan dapat diteruskan untuk mengambil atau menghantar.',
      failed_cancel_shipment: 'Gagal mengesahkan penghantaran'
    },
    profile: {
      home: 'Laman Utama',
      my_profile: 'Profil Saya',
      pickup: 'ALAMAT',
      address: 'PENGAMBILAN',
      delete: 'Padam',
      pickup_address: "Alamat Pengambilan",
      select_address_list: "Pilih Alamat Pengambilan daripada senarai di bawah untuk melihat dan meminda butiran atau menambah alamat baharu",
      save_default_address: "Simpan sebagai alamat tetap",
      update: "Kemas Kini",
      submit: 'Hantar',
      save: "Simpan",
      search_contact: "Cari Kenalan",
      company: 'Nama Syarikat',
      personal_details: 'Maklumat peribadi',
      my_addresses: 'Alamat Saya',
      hide_address: 'Sembunyikan Alamat',
      hide_pickup_address: 'Ya, sembunyikan alamat pengambilan saya',
      hide_address_note: 'Nota: Alamat anda kini akan disembunyikan dari penerima.',
      hide_address_confirm: 'Pasti',
      address_update_toaster: 'Pilihan sembunyikan alamat telah dikemaskini.',
      address_update_toaster_error:'Oops. Pilihan sembunyikan alamat tidak dikemas kini.',
      fetch_config_error:'Ralat untuk konfigurasi:',
    },
    contact:{
      create_contact: "BUAT KENALAN",
      home: 'Laman Utama',
      contact: 'Kenalan',
      sort_all_contact: "Susun semua kenalan anda dengan mudah",
      create_new_contact: "Buat Kenalan Baharu",
      create_new_contact1: "BUAT KENALAN BAHRU",
      contact_all: "KENALAN SAYA (SEMUA)",
      search: "Cari Kenalan",
      delet: "Padam",
      download: "Muat Turun Semua",
      contact_person: "ORANG KENALAN",
      contact_no: "NO. KENALAN",
      email: "EMEL",
      address: "ALAMAT",
      company: "SYARIKAT",
      shipments: 'TERTUNGGU',
      create__contact: "Buat Kenalan",
      save_contact: "Simpan",
      add_contact_note: 'Adakah anda pasti mahu menambah kenalan ini?',
      bulk_upload: 'Muat Naik Pukal Kenalan',
      would_like_to_delete: 'Adakah anda ingin memadam yang dipilih',
    },
    billing:{
      billing: 'Pengebilan',
      download: 'Muat turun',
      back_data: 'Kembali ke Pengambilan',
      invoices: 'Invois',
      invoice_date: 'Tarikh Invois',
      invoice_no: 'Nombor invois',
      period: 'Tempoh',
      amount: 'Jumlah',
      action: 'Tindakan',
      no_data_is_available: 'Tiada data tersedia',
      invalid_date: "Tarikh tidah sah",
      date_time: 'Masa/Tarikh',

      online_payment: 'PEMBAYARAN DALAM TALIAN',
      important_notice:  'Nota penting',
      please_select_invoice: 'Sila pilih invois anda untuk membuat pembayaran dalam talian.',
      payment_note: 'Setelah dibayar, ia boleh mengambil masa sehingga 24 jam untuk status akaun anda dikemas kini dalam SendParcel Pro.',
      payment_status: 'Jika status pembayaran anda "Belum selesai", sistem akan mengemas kini kepada status terkini dalam masa 2 jam.',
      pay_now: 'Bayar sekarang',
      invoice_selected: 'invois dipilih',
      already_paid_error: 'Satu atau lebih Invois yang anda pilih telah dibayar.',

      pending: 'BELUM SELESAI',
      paid: 'DIBAYAR',
      failed: 'GAGAL',
      home: 'Laman Utama',
    },
    pickup_data:{
      update: 'Kemas kini',
      add: 'Tambah',
      pick_up_address: 'Alamat Pengambilan',
      send_shipment_note: "Hantar satu penghantaran",
      delete_address_note: 'Adakah anda mahu memadamkan alamat pengambilan ini?',
      delete_address_note2: 'Alamat Pengambilan telah Berjaya dipadamkan!',
      close: 'Tutup',
      update_address_note: 'Adakah anda mahu mengemas kini alamat pengambilan ini?',
      personal_details: 'Maklumat peribadi',
    },
    dialog_box_data:{
      successful: 'berjaya',
      note: 'Sila teruskan ke tab "Penghantaran Tertunggu" untuk mencetak nota konsainan',
      failed: 'gagal',
      request_pickup: 'Sila cuba semula permintaan untuk mengambil',
      error_msg: 'Satu atau lebih data tidak betul, sila cuba lagi.',
      error_title: 'Kami menemui ralat semasa penyerahan!',
      confirm: 'Pasti',
      error_message1: 'Kami menghadapi ralat sistem. Sila cuba sebentar lagi.',
      error_message2: 'Kami mengalami kegagalan dalam penghantaran ini. Sila cuba semula. Terima kasih.',
      error_message3: 'Nampaknya akaun anda telah disekat. Sila hubungi Pengurus Akaun anda untuk mendapatkan bantuan.',
      error_message4: 'Kami tidak dapat memproses permintaan ini. Sila cuba sebentar lagi.',
      error_message5: 'Sesuatu telah berlaku. Sila cuba sebentar lagi.',
      error_message6: 'Akun Anda tidak aktif. Silakan hubungi pemilik akun untuk informasi lebih lanjut.',
      error_message7: 'Pengguna ini tidak mempunyai akses yang diperlukan.',

      need_at_least_one_connote: "Memerlukan sekurang-kurangnya 1 ID berkonotasi untuk menambahkan item ke dalam  permintaan pengambilan",
      no_record_to_download: 'Tiada Rekod untuk Muat Turun',
      duration_of_days: 'Tempoh lebih daripada 90 hari',
      i_change_my_mind: 'Saya tukar fikiran',
      uh_oh: 'Uh-oh',
      close: 'Tutup',
    },
    smart_fill_data:{
      title: 'Pengisian Pintar',
      note1: 'Tampal butiran penerima dalam sebarang pesanan dan kami akan mengisi medan borang anda',
      note2: '(Alamat Malaysia sahaja).',
      note3: 'E.g. Muhammad Ali',
      note4: '0125456789',
      note5: '51, Dayabhumi Complex 50480 Kuala Lumpur',
      note6: 'muhammad.ali@gmail.com',
      submit: 'Hantar',
    },
    report: {
      reports: 'Laporan',
      generate_a_report: 'JANA LAPORAN',
      select_date_range: 'Pilih tarikh',
      filter_by_status: 'Saring mengikut status',
      required_filter: 'Sila pilih satu atau lebih status',
      generate_report: 'Jana laporan',
      result: 'KEPUTUSAN',
      date: 'Tarikh',
      file_name: 'Nama fail',
      action: 'Tindakan',
      all: 'Semua',
      pickup_requested: 'Pengambilan Sudah Dijadualkan',
      pickup_rescheduled: 'Pengambilan Dijadualkan Semua',
      out_for_delivery: 'Keluar untuk penghantaran',
      picked_up: 'Sudah diambil',
      droppedoff: 'Sudah diserah',
      in_transit: 'Dalam transit',
      delivered: 'Sudah dihantar',
      returned: 'Dipulang semula',
      failed: 'Gagal dihantar',
      cod: 'COD (Tunai Semasa Penyerahan)',
      non_cod: 'Bukan COD (Tunai Semasa Penyerahan)',
      report_error_note: 'Maksimum hanya 5 laporan boleh dijana pada satu masa.',
      report_generated_successfully:'Laporan berjaya dijana!',
      please_fill_the_form: 'Sila isi borang',
      file_not_available: 'Fail tidak berjaya dimuat turun',
      close: 'Tutup',
      no_record_to_download: 'Tiada Rekod untuk Muat Turun',
      duration_of_days: 'Tempoh lebih daripada 90 hari',
      home: 'Laman Utama',
    },
    rate_calulator: {
      pos_laju_shipment_rate_calculator: 'KALKULATOR KADAR PENGHANTARAN POS LAJU',
      shipment_rate_calculator: 'Kalkulator kadar penghantaran',
      rate_card: 'Kad Kadar',
      shipment_details: 'Butiran Penghantaran',

      calculating: 'Sedang mengira...!',
      generate_quote: 'Sebut Harga Am',
      important_note: 'Nota Penting:',
      note1: 'Sila simpan kad kadar olahan anda pada seksyen di bawah untuk memaksimakan kepenggunaan kalkulator kadar.',
      note2: 'Kalkulator ini direka khas untuk penghantaran Pos Laju domestik dan harus digunakan dengan betul.',
      note3: 'Sila ambil maklum bahawa jumlah yang dijana oleh kalkulator adalah nilai anggaran sahaja. Untuk maklumat pengebilan yang tepat, sila rujuk invois anda.',
      note4: 'Adalah dinasihatkan supaya kadar yang tertera pada kad kadar disemak silang dengan kerap bagi memastikan ketepatan dan sejajar dengan perjanjian harga.',
      shipment_rate: 'Kadar penghantaran',
      estimated_total: 'Jumlah Anggaran',

      to_edit_rate_card: 'Cara mengedit kad kadar anda:',
      input_your_rates: 'Masukkan kadar anda:',
      input_your_rates_note: 'Sila masukkan kadar anda di dalam ruangan yang disediakan untuk menggunakan kalkulator kadar dengan efektif',
      rate_inpute_requirement: 'Keperluan kemasukan kadar:',
      rate_inpute_requirement_note: 'Kadar anda adalah input untuk Zon 1, 2, 3 / Zon 4 / Zon 5. Rujuk perjanjian harga untuk kadar spesifik dan maklumat zon.',
      example: 'Contoh',
      example_note: 'Untuk Zon 1, 2, 3, kadar untuk 3kg pertama adalah RM6, dan kadar tambahan se-kg adalah RM1.50.',
      example_note1: 'Untuk memasukkan kadar-kadar ini:',
      example_note2: "Pada Zon 1, 2, 3 (seksyen biru), guna senarai 'dropdown' berat untuk memilih 3.",
      example_note3: 'Masukkan nilai 6 pada ruangan harga.',
      example_note4: 'Pilih 1 pada ruangan tambahan (se-kg) dan masukkan nilai 1.50 pada ruangan harga.',
      example_note5: 'Klik "Simpan & Pakai" untuk mengesahkan perubahan.',

      additional: 'Tambahan (se-kg)',
      zone: 'Zon',
      add_weight: 'Tambah Berat',
      amount_exceed: "Amaun melebihi had RM100",
      min_amount: "Masukkan minimum RM1",
      price: 'Harga (RM)',
      add_additional_weight: 'Tambah Berat Tambahan',
      set_price: 'Tetapkan Harga',

      close: 'Tutup',
      error_while_fetching_rate_calculation_data: 'Ralat terjadi sewaktu mendapatkan data pengiraan kadar',
      no_rate_cards_available: 'Tiada kad kadar tersedia.',
      error_while_fetching_rate_data: 'Ralat terjadi sewaktu mendapatkan kad-kad kadar.',
      failed_to_fetch_data: 'Gagal untuk mendapatkan data',
      configure_your_rate_card:'Sila tetapkan kad kadar anda pada seksyen di bawah untuk menggunakan kalkulator ini.',
      error_fetching_zone_data: 'Ralat mendapatkan data zon. Sila cuba semula.',
      zone_information_not_available: 'Tiada maklumat zon tersedia.',
      invalid_weight_input: 'Nilai berat tidak sah.',
      error_calculating_shipping_cost: 'Ralat pada pengiraan kos penghantaran.',
      invalid_postcode: 'Poskod tidak sah',
      no_rate_card_found_for_zone: 'Tiada kad kadar dijumpai',
      your_rate_card_has_been_configured: 'Kad kadar anda telah dikonfigurasikan.',
      your_rate_card_is_not_configured: 'Kad kadar anda gagal dikonfigurasi.',
      rate_calculator: 'Kalkulator kadar',
      home: 'Laman Utama'
    },
    user_management:{
      home: 'Laman Utama',
      my_profile: 'Profil Saya',
      user_management: 'Pengurusan Pengguna',
      add_user: 'Tambah Pengguna',
      name: 'Nama',
      email_address: 'Alamat E-mel',
      resend: 'Hantar Semula',
      account_access: 'Akses Akaun',
      status: 'Status',
      not_activated: 'Tidak Aktif',
      action: 'Tindakan',
      no_data_available: 'Tiada data',

      are_you_confirmed_to_remove_this_user: 'Anda pasti untuk membuang pengguna ini?',
      email_has_been_sent_successfully: 'E-mel telah berjaya dihantar ',
      resend_email_has_been_failed: 'E-mel tidak berjaya dihantar semula',

      full_name: 'Nama Penuh',
      please_enter_your_name: 'Sila masukkan nama anda',
      account: 'Akaun',
      more: 'Lagi',
      please_select_an_account: 'Sila pilih akaun',
      please_select_one_or_more: 'Sila pilih satu akaun atau lebih',
      require_email: 'Sila masukkan e-mel anda',
      require_valid_email: 'Sila masukkan e-mel yang betul',
      update: 'Kemas kini',
      submit: 'Hantar',
      update_user: 'Kemas kini pengguna',
      send_email1: 'E-mel telah dihantar ke',
      send_email2: 'Penerima dikehendaki mencipta butiran log masuk.',
      ok_got_it: 'Ok, saya faham',
      email_msg1: 'E-mel',
      email_msg2: 'sudah wujud',
      user_creation_failed: 'Gagal cipta pengguna',
      error_msg1: 'Oops, Pengguna tidak mempunyai akses yang diperlukan.',
      error_msg2: 'Pengguna telah berjaya dikemas kini',
    },
    form_data: {
      three: 3,
      five: 5,
      seven: 7,
      eight: 8,
      ten: 10,
      minlength: 'Minimum',
      characters : 'aksara',

      required_receipent: 'Masukkan nama penerima',
      required_company: 'Masukkan nama Syarikat',
      required_receipent_address: 'Masukkan alamat penerima',
      required_receipent_email: 'Masukkan alamat e-mel penerima',
      required_valid_email: 'Masukkan alamat e-mel yang sah',
      required_receipent_postcode: 'Masukkan poskod penerima',
      required_valid_postcode: 'Masukkan poskod yang sah',
      invalid_postcode: 'Poskod tidak sah',
      required_state: 'Negeri penerima diperlukan',
      required_city: 'Bandar penerima diperlukan',
      required_pickup:'Masukkan Nama Pickup.',
      required_pickup_postcode: 'Masukkan Poskod Pickup.',

      country: 'Negara',
      required_country: 'Negara diperlukan',

      code: 'Kod',
      required_code: 'Kod Dail diperlukan',
      phone: 'Telefon',
      required_phone:'No. telefon diperlukan',
      required_valid_phone: 'Masukkan telefon yang sah',
      required_name: 'Masukkan nama',

      search: 'Cari',
      no_result: 'Tiada keputusan',

      recipient_name: 'Nama Penerima',
      company_name: 'Nama Syarikat',
      email_address: 'Alamat Emel',
      address: 'Alamat',
      postcode: 'Poskod',
      state: "Negeri",
      city: "Bandar",
      name: "Nama",

      save_default_address: "Simpan sebagai alamat tetap",
      add_pickup_address: "Tambah Alamat Pengambilan",
      update: "Kemas Kini",
      submit: 'Hantar',
      save: "Simpan",
      delete: 'Padam',
      save_changes: 'Simpan',
      complete: 'Selesai',
      save_to_contact: "Simpan Kenalan",
      close: 'Tutup',
      back: 'Kembali',
      amountError: 'Sila masukkan amaun COD dalam nombor sahaja.',
      validAmount: 'Sila masukkan amaun COD dalam jumlah yang betul.',
      codCheckboxError:'Pilihan COD tidak diproses.',
      minAmountError: 'Minimum amaun untuk COD adalah RM1.',
      unexpectedError: 'Terdapat ralat yang tidak dijangka. Sila cuba sebentar lagi.',

      category: 'Kategori',
      required_category: 'Pilih kategori bungkusan',
      no_of_shipments:'Bilangan penghantaran',
      width: 'Lebar (cm)',
      required_width: 'Masukkan lebar dalam cm',
      required_valid_width: 'Masukkan lebar yang sah',
      length: 'Panjang (cm)',
      required_length: 'Masukkan panjang dalam cm',
      required_valid_length: 'Masukkan panjang yang sah',
      height: 'Tinggi (cm)',
      required_height: 'Masukkan tinggi dalam cm',
      required_valid_height: 'Masukkan tinggi yang sah',
      total_weight: 'Jumlah Berat (kg)',
      weigth: 'Berat (kg)',
      required_weight: 'Masukkan berat dalam kg',
      required_valid_weight: 'Masukkan berat yang sah',
      volumetric_weight: 'Berat Volumetrik (kg)',
      chargeable_weight: 'Berat Dikenakan Caj',
      required_volumetric_weight: 'Berat volumetrik diperlukan',
      required_chargeable_weight: 'Berat Dikenakan diperlukan',
      description_content: 'Huraian Kandungan',
      required_description_content: 'Diskripsi diperlukan',
      amount: 'Jumlah (RM)',
      required_amount: 'Masukkan Jumlah (RM)',
      required_valid_amount: 'Masukkan amaun yang sah',
      required_min_amount: 'Jumlah minimum ialah RM1',
      required_max_amount: 'Jumlah COD melebihi had',
      required_max_amount_exceeds_limit: 'Melebihi jumlah maksimum yang dibenarkan',
      sum_insured: 'Jumlah Insurans (RM)',
      required_sum_insured:  'Masukkan Jumlah Insuran (RM)',
      required_valid_sum_insured: 'Masukkan amaun diinsurankan yang sah',
      required_min_sum_insured: 'Jumlah minimum ialah RM1',
      required_max_sum_insured: 'Nilai maksimum sehingga RM10,000',
      premium_amount: 'Jumlah Premium (RM)',
      required_valid_premium_amount: 'Masukkan jumlah premium',
      product: 'Produk',
      select_product:'Pilih produk bungkusan',
      category_detail: 'Butiran kategori',
      required_category_detail: 'Pilih detailkategori bungkusan',
      item_description: 'Huraian Item',
      required_item_description: 'Masukkan diskripsi item',
      quantity: 'Kuantiti',
      required_quantity: 'Masukkan kuantiti',
      required_valid_quantity: 'Masukkan kuantiti yang sah',
      value:'Nilai (RM)',
      required_value: 'Masukkan nilai dalam RM',
      required_valid_value: 'Masukkan nilai yang sah dalam RM',
      extra_charges_warning_message: 'Caj tambahan akan dikenakan untuk bungkusan yang melebihi had berat dan saiz',
      total_sum_insured: 'Jumlah Insuran Keseluruhan (RM)',
      required_total_sum_insured: 'Nilai maksimum sehingga RM10,000',
      parent_shipment: 'Penghantaran Induk',
      child_shipment: 'Penghantraan Anak',
      pickup_added_success_note: "Alamat pengambilan telah ditambah",

      created: 'dicipta',
      updated: 'dikemas kini',
      create_shipment: 'Buat Penghantaran',
      get_consignment_note:"Dapatkan Konsainan",
      request_for_pickup: 'Minta Pengambilan',
      shipment_is: 'Penghantaran anda telah ',
      please_proceed_to: 'Sila teruskan ke',
      generate_consigment_note: 'Jana Nota Konsainan',
      download_consignment_note:'Sila muat turun dan cetak nota konsainan anda yang dikemas kini',
      continue: 'Teruskan',
      download: 'Muat turun',
      do_you_wish_to_create_more_orders: 'Adakah anda ingin membuat lebih banyak pesanan?',
      create_more_orders: 'Buat Lebih Banyak Pesanan',

      pickup_request: 'Adakah anda ingin membuat Permintaan Pengambilan?',
      select_date:'Pilih Tarikh',
      edit_date: 'Ubah Tarikh',
      date_note: 'Pra-pilih tarikh pengambilan adalah berdasarkan tarikh terdekat (sebelum masa terputus).',
      edit_date_note: 'Untuk menjadualkan pengambilan ke tarikh lain, klik “Ubah Tarikh',
      request_pickup: 'Minta pengambilan',
      required_date: 'Tarikh diperlukan',
      required_valid_date: 'Medan bukan tarikh yang sah.',
      required_current_date: 'Tarikh tidak boleh pada masa lalu.',
      parcel: "Bunkusan",
      document: 'Dokumen',
      merchandise: 'Barang Dagangan',
      gift: 'Hadiah',
      sales_of_goods: 'Jualan barang',
      commercial_sample: 'Sampel Komersial',
      returned_goods: 'Barangan Dipulangkan',
      others: 'Lain-lain',

      state_from: 'Dari Negeri',
      state_to: 'Ke Negeri',
      max_weight_30kg:'Berat Maks. : 30kg',
      max_weight: 'Melebihi berat maksimum',
      required_weight_more_than_0: 'Masukkan berat lebih daripada 0 kg.',

      cancel: 'Batal',
      edit: 'Edit',
      save_apply: 'Simpan & Pakai',
    },
    error_handling:{
      return_to_home: 'Kembali ke halaman utama',
      page_not_found: 'Lama tidak dijumpai',
      link_not_found: 'Pautan tidak ditemui atau telah tamat tempoh.',

      shipment_tracking_link_is_copied: 'Pautan nombor penjejakan disalin!',
      failed_to_copy_shipment_tracking_link: 'Pautan nombor penjejakan gagal disalin',
      close: 'Tutup'
    }
  },
};
